import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as XIcon } from '../../assets/icons/XIcon.svg';
import { ReactComponent as UniqueDesign } from '../../assets/icons/UniqueDesign.svg';

type Type = {
	modalIsOpen: boolean;
	closeModal?: () => void;
	onCancel?: () => void;
	onConfirm?: () => void;
	saveDraft?: () => void;
};

function DeleteCurrentDesignModal({ modalIsOpen, closeModal, onConfirm, onCancel = () => {}, saveDraft }: Type) {
	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
		}
	}, [modalIsOpen]);
	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {}}
			onRequestClose={onCancel}
			className="no-scroll border-0"
			style={{
				overlay: {
					zIndex: 5,
					background: '#21187F2a',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				},
			}}
		>
			<div className=" h-[354px] w-full bg-white p-10 rounded-[12px] relative">
				<div className="cursor-pointer absolute top-[20px] right-[20px]" onClick={onCancel}>
					<XIcon />
				</div>

				<div className="mt-[80px] mb-[60px] flex flex-col gap-[20px]">
					<p className="text-[24px] text-center">Are you sure you want to delete your design?</p>
					<p className="text-[14px] text-center">Your design progress will be lost. Return to save before you start over.</p>
				</div>

				<div className="flex flex-row gap-[5px]">
					<button className="flex-1 border border-laykaDarkPurple text-laykaDarkPurple h-[40px] text-[14px] rounded-full text-center font-bold uppercase" onClick={saveDraft}>
						save your design
					</button>
					<button className="flex-1 bg-laykaDarkPurple text-white h-[40px] rounded-full text-center text-[14px] font-bold uppercase" onClick={onConfirm}>
						Delete design
					</button>
				</div>
			</div>
		</ReactModal>
	);
}

export default DeleteCurrentDesignModal;
