import React, { useEffect, useState } from 'react';
import { getCountries, createCountries } from '../../../server/server';
import axios from 'axios';
import { AnyARecord } from 'dns';

interface SignIn {
	setSelectedMode: any;
	openModal: any;
}

function Country({ setSelectedMode, openModal }: SignIn) {
	const [countries, setCountries] = useState([]);
	useEffect(() => {
		fetchCountries();
	}, []);

	const loadCountries = async () => {
		const resp = await getCountries();
		if (resp.status === 200) {
			if (resp.data?.length === 0) {
				//automatski popunjava bazu sa scriptom countries koja je na backu todo izbaciti kad dodje vreme!!!
				const response = await createCountries([]);
				if (response.status === 201) {
					setCountries(response.data);
				}
			} else {
				setCountries(resp.data);
			}
		}
	};

	const fetchCountries = async () => {
		try {
			const response = await axios.get('https://restcountries.com/v2/all');
			setCountries(response.data);
		} catch (error) {
			console.error('Error fetching countries:', error);
		}
	};

	const selectCountry = () => {};

	return (
		<div className="w-full h-full bg-white flex flex-col items-center justify-center ">
			<div className="flex h-full flex-col max-w-[430px] w-full px-3 ">
				<p className="text-[30px] leading-[36px] text-black  font-bold  mt-[100px]">Choose your Country</p>
				<p className="text-[18px] leading-[20px] text-black  font-normal  mt-5">To see available products</p>
				<div className="max-h-[calc(100%-280px)] overflow-auto flex flex-col mt-[30px] no-scroll">
					{countries.map((country: any) => (
						<div className="flex flex-row items-center justify-between mb-[10px] p-4 cursor-pointer " key={country.alpha3Code} onClick={() => openModal()}>
							<div className="flex flex-row items-center gap-[10px]">
								<img src={country.flag} alt={`${country.name} flag`} width="16" height="12" />
								<div className="text-[16px] leading-[20px] text-black  font-normal">{country.name}</div>
							</div>
							<div className="flex items-center justify-center border border-laykaDarkPurple min-h-[16px] min-w-[16px] rounded-full">
								<div className="min-w-[8px] min-h-[8px] bg-laykaDarkPurple rounded-full"></div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default Country;
