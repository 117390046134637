import React, { useEffect, useState } from 'react';
import { getAllCategories, getImage } from '../../server/server';
import { ReactComponent as ArrowRightUp } from '../../assets/icons/ArrowRightUp.svg';
import { useNavigate } from 'react-router-dom';
import { useMenuItems } from '../../hooks/useMenuItems';

function CategoriesHome() {
	const [categories, setCategories] = useState<any[]>([]);
	const { menuItems, setMenuItems } = useMenuItems();

	const navigate = useNavigate();

	const navigateToProductType = (type: string) => {
		navigate(`/products?type=${type}`.toLowerCase()?.replaceAll('&', '%26'));
	};

	return (
		<div className="py-[120px] w-full flex items-center justify-center   bg-white px-[20px]">
			<div className="max-w-[1280px] w-full flex flex-col items-center">
				<h2 className="text-[46px] font-bold text-black leading-[54px] text-center ">Perfect gift for every occasion</h2>
				<p className="text-[14px] font-normal text-black leading-[18px] text-center max-w-[716px] mt-5">
					Discover the ultimate gift for every occasion – the perfect present awaits you!
					<br />
					Whether you're celebrating birthdays, anniversaries, or simply want to show someone you care, our collection ensures that you'll find the ideal gift to match the mood and sentiment of the occasion.
				</p>
				<div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-5 mt-9 w-full">
					{menuItems.map((category: any, index: number) => (
						<button className="rounded-[12px] overflow-hidden w-full aspect-square flex-1 flex flex-col ease-in-out transition-all duration-[230ms] hover:scale-105" key={index} onClick={() => navigateToProductType(category.name)}>
							<div className="flex-1 flex flex-row w-full bg-babyBlue overflow-hidden">
								<img src={category?.image ? getImage(category?.image.id) : require('../../assets/images/Slika.webp')} className="w-full object-cover h-full" alt={category?.sku} title={category?.name} />
							</div>
							<div className="border border-babyBlue min-h-[56px] rounded-b-[12px] px-[12px]  w-full flex flex-row justify-between items-center">
								<div className="gap-[6px] flex-row items-center flex">
									<img src={!!category?.seo?.metaImage ? getImage(category?.seo.metaImage.id) : require('../../assets/images/Slika.webp')} className="flex-1 h-[26px] object-cover" alt={category?.sku} title={category?.name} />
									<p className="text-[20px] leading-[28px] font-medium">{category?.name}</p>
								</div>
								<div>
									<ArrowRightUp />
								</div>
							</div>
						</button>
					))}
				</div>
			</div>
		</div>
	);
}

export default CategoriesHome;
