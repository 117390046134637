import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as XIcon } from '../../assets/icons/XIcon.svg';
import { ReactComponent as EaseShopping } from '../../assets/icons/EaseShopping.svg';
import { Link } from 'react-router-dom';

type Type = {
	modalIsOpen: boolean;
	closeModal?: () => void;
	onCancel?: () => void;
	goToCart?: () => void;
	exploreProducts?: () => void;
};

function AddedToCartModal({ modalIsOpen, closeModal, goToCart, onCancel = () => {}, exploreProducts }: Type) {
	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
		}
	}, [modalIsOpen]);
	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {}}
			onRequestClose={onCancel}
			className="no-scroll border-0"
			style={{
				overlay: {
					zIndex: 5,
					background: '#21187F2a',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				},
			}}
		>
			<div className="  w-full bg-white p-10 rounded-[12px] relative">
				<div className="mt-[38px] mb-[30px] flex flex-col gap-[8px] items-center">
					<EaseShopping width={150} height={150} />

					<p className="text-[20px] text-center">Product added to cart successfully!</p>
				</div>

				<div className="flex flex-row gap-[5px]">
					<button className="flex-1 border border-laykaDarkPurple text-laykaDarkPurple h-[40px] text-[14px] rounded-full text-center font-bold uppercase" onClick={exploreProducts}>
						Explore more products
					</button>

					<button className="flex-1 bg-laykaDarkPurple text-white h-[40px] rounded-full text-center text-[14px] font-bold uppercase" onClick={goToCart}>
						go to checkout
					</button>
				</div>
			</div>
		</ReactModal>
	);
}

export default AddedToCartModal;
