import React from 'react';
import { UserProvider } from '../auth/UseUser';
import { CartProvider } from '../hooks/useCart';
import { MenuItemsProvider } from '../hooks/useMenuItems';

const AppProviders = ({ children }: any) => {
	return (
		<UserProvider>
			<CartProvider>
				<MenuItemsProvider>{children}</MenuItemsProvider>
			</CartProvider>
		</UserProvider>
	);
};

export default AppProviders;
