import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as XIcon } from '../../assets/icons/XIcon.svg';
import { ReactComponent as UniqueDesign } from '../../assets/icons/UniqueDesign.svg';

function GreetingModal({ modalIsOpen, closeModal, product, reloadFonts }: any) {
	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
		}
	}, [modalIsOpen]);
	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {}}
			onRequestClose={closeModal}
			className="no-scroll border-0"
			style={{
				overlay: {
					zIndex: 5,
					background: '#21187F2a',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				},
			}}
		>
			<div className=" h-[415px] w-full bg-white p-10 rounded-[12px]">
				<div className=" flex flex-1 flex-row justify-end  ">
					<div className="cursor-pointer" onClick={() => closeModal()}>
						<XIcon />
					</div>
				</div>
				<div className="flex flex-row justify-center mt-[10px]">
					<UniqueDesign width={213} height={165} />
				</div>
				<p className="text-[30px] leading-[36px] font-bold text-black text-center uppercase mt-5">
					Thank you for
					<br /> your order!
				</p>
				<p className="text-[16px] leading-[24px] font-normal text-black text-center  mt-5">Your order has been successfully submitted!</p>
			</div>
		</ReactModal>
	);
}

export default GreetingModal;
