import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({
	title = 'Layka - Discover unique products tailored just for you',
	description = 'Layka app transforms your shopping experience with personalized products tailored to your style and preferences. Discover unique, customizable items that reflect your individuality. Shop now for a truly personalized experience.',
}: {
	title?: string;
	description?: string;
}) {
	return (
		<Helmet>
			{/* Standard metadata tags */}
			<title>{title}</title>
			<meta name="description" content={description} />
			{/* End standard metadata tags */}
			{/* Facebook tags */}
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content={title} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			{/* <link rel="og:image" href="%PUBLIC_URL%/HomeScreenImage.webp" /> */}
			{/* End Facebook tags */}
			{/* Twitter tags */}
			<meta name="twitter:creator" content="AIO PRO d.o.o." />
			<meta name="twitter:card" content="website" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{/* End Twitter tags */}
		</Helmet>
	);
}
