import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { baseUrl } from './connections';
// export const baseUrl = 'https://layka-api.we-deliver.net';
// export const baseUrl = 'http://localhost:3003';
//export const baseUrl = 'http://localhost:3003';

//export const baseUrl = 'https://npaa-api.chainapptech.net';
interface LoginDto {
	email: string;
	password: string;
}

function handelError(error: any) {
	return { status: error?.response?.status, data: error?.response?.data };
}
function handleSuccess(response: any) {
	const data = !!response.data ? response.data : response.data;
	const responseData = parseJson(data);
	return { status: response.status, data: responseData };
}
const parseJson = (str: any) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
};
export async function login(user: LoginDto) {
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/signin`,
		data: user,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function signUp(user: any) {
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/signup`,
		data: user,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertUser(user: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/user/insert`,
		data: user,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function addExpenditure(b: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/expenditure`,
		data: b,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function updateNatMeasJurastiction(nm: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/natmeas/jurastiction/update`,
		data: nm,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertMeasure(nm: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/measure/insert`,
		data: nm,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function measureStatus(nm: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/measure/status`,
		data: nm,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function updateHelpPage(page: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/help/page/update`,
		data: page,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertPointer(page: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/pointer/insert`,
		data: page,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function editHarmonizationCompatibility(h: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/natmeasure/harmonizations/compatibility`,
		data: h,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertNoCelex(i: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/nocelexregister/insert`,
		data: i,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function editNoCelex(i: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/nocelexregister/edit`,
		data: i,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertHarmonization(h: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/natmeasure/harmonizations`,
		data: h,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertNationalMeasureInChargeInstitutions(h: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/natmeasure/inchargeinstitution`,
		data: h,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertNationalMeasureRelation(r: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/natmeasure/relation`,
		data: r,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function editUser(user: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/user/edit`,
		data: user,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertOJ(oj: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/oj/insert`,
		data: oj,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function editOJ(oj: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/oj/edit`,
		data: oj,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertNatGroupStructure(structure: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/natmeasure/group/structure`,
		data: structure,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function editUserPassword(user: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/user/edit/password`,
		data: user,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertOJNumber(ojNum: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/oj/number/insert`,
		data: ojNum,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function editOJNumber(ojNum: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/oj/number/edit`,
		data: ojNum,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertInstitution(institution: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/institutions/insert`,
		data: institution,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertNatMeasure(natMeasure: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/natmeasure/insert`,
		data: natMeasure,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function editNatMeasure(natMeasure: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/natmeasure/edit`,
		data: natMeasure,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function updateInstitution(institution: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/institutions/update`,
		data: institution,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function whoAmI() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/user/whoami`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getNationalGroupSet(group: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/nationalgroupset?group=${group}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getExpenditures(limit: any, offset: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/expenditures?limit=${limit}&offset=${offset}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getNatGroupStructure(id: any, set: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/natmeasure/group/structure?id=${id}&set=${set}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getNationalGroupSetByLevel(set: any, level: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/nationalgroupsetbylevel?setNo=${set}&level=${level}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getScreeningGroups(parent: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/groups/screening?parent=${parent}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getEuMeasureBy(limit: any, offset: any, celexNo: any, group: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/eumeasures/by?limit=${limit}&offset=${offset}&celexNo=${celexNo}&group=${group}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getNatMeasureBy(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/natmeasure/by?id=${id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAdministrationUserInsert() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/user/insert`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getDefaultRole() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/settings/defaultRole`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAllRoles() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/settings/roles`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getEuStats() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/administration/eu/stats`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function updateUserRole(id: string, roleId: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/settings/user/role/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: { id, roleId },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function updateSettings(id: string, value: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/settings/update/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: { value },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getAllUsers(
	limit: any,
	offset: any,
	username: any,
	roleId: any,
	institutionId: any,
	name: any,
	orderByFieldName: string, //column name
	orderByName: string //sorting direction
) {
	console.log('getAllUsers');
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/administration/users`,
		params: {
			limit,
			offset,
			username,
			roleId,
			institutionId,
			name,
			order: orderByFieldName ?? null,
			by: orderByName ?? null,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getRegistersManagement(limit: any, offset: any, term: any, institutionId: any, role: any = '') {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/register/natmeasures?limit=${limit}&offset=${offset}&term=${term}&institutionId=${institutionId}&role=${role}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMeasures(limit: any, offset: any, term: any, institutionId: any, filter: any) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/views/measures`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: {
			limit,
			offset,
			institution: institutionId,
			term,
			filter,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getPointers(id: any) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/register/measure/pointers`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: {
			id,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMeasureById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/register/measure/info?id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getInstCelexViews(limit: any, offset: any, institutionId: any, filter: any, from: any, to: any) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/views/institutioncelex`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: {
			limit,
			offset,
			institution: institutionId,
			filter,
			from,
			to,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function GetViewsQInstitution(limit: any, offset: any, institutionId: any, filter: any, from: any, to: any) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/views/realisationinstitution`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: {
			limit,
			offset,
			institution: institutionId,
			filter,
			from,
			to,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function GetViewsNPICelex(limit: any, offset: any, group: any, filter: any, from: any, to: any) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/views/npicelex`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: {
			limit,
			offset,
			group,
			filter,
			from,
			to,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getNatMeasuresSimple(limit: any, offset: any, term: any, searchId: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/register/natmeasures/simple?limit=${limit}&offset=${offset}&term=${term}&searchId=${searchId}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAllInstitutions() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/administration/institutions/all`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getAllOfficialJournals() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/administration/journals/all`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getOfficialJournals(offset: any, limit: any, term: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/administration/journals?offset=${offset}&limit=${limit}&term=${term}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAdministrationNoCelexRelations(offset: any, limit: any, term: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/administration/nocelexregisters?offset=${offset}&limit=${limit}&term=${term}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAdministrationNoCelexRelationById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/administration/nocelexregister?id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function uploadTUFile(file: any, id: any) {
	const token = localStorage.getItem('accessToken');
	const formData = new FormData();
	formData.append('file', file);
	formData.append('id', id);
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/administration/tufile/upload`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getTUFileById(id: any) {
	// const token = localStorage.getItem('accessToken');
	// const options: AxiosRequestConfig = {
	//   method: 'GET',
	//   url: `${baseUrl}/administration/tufile/${id}`,
	//   headers: {
	//     Authorization: `Bearer ${token}`,
	//   },
	// };
	// const contentDisposition = response.headers['content-disposition'];
	// const fileName = contentDisposition.substring(
	//   contentDisposition.indexOf('=') + 1
	// );

	// return axios(options)
	//   .then((response) => handleSuccess(response))
	//   .catch((error) => handelError(error));

	const token = localStorage.getItem('accessToken');
	const response = await axios.get(`${baseUrl}/administration/tufile/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob', // specify the response type as 'blob'
	});

	const contentDisposition = response.headers['content-disposition'];
	const name = contentDisposition?.split('="')[1].replace('"', '');
	console.log('ovde', response.headers);
	return {
		data: {
			data: response.data,
			name,
			type: response.headers['content-type'],
		},
		status: response?.status,
	};
}
export async function getInstitutionCelexFile(i: any, from: any, to: any) {
	const token = localStorage.getItem('accessToken');
	const response = await axios.get(`${baseUrl}/views/doc/institution-celex?institution=${i}&from=${from}&to=${to}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob', // specify the response type as 'blob'
	});

	const contentDisposition = response.headers['content-disposition'];
	const name = contentDisposition?.split('="')[1].replace('"', '');

	return {
		data: {
			data: response.data,
			name,
			type: response.headers['content-type'],
		},
		status: response?.status,
	};
}
export async function getFinDoc() {
	const token = localStorage.getItem('accessToken');
	const response = await axios.get(`${baseUrl}/views/doc/fin`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob', // specify the response type as 'blob'
	});

	const contentDisposition = response.headers['content-disposition'];
	const name = contentDisposition?.split('="')[1].replace('"', '');

	return {
		data: {
			data: response.data,
			name,
			type: response.headers['content-type'],
		},
		status: response?.status,
	};
}
export async function getMeasureDoc() {
	const token = localStorage.getItem('accessToken');
	const response = await axios.get(`${baseUrl}/views/doc/measure`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob', // specify the response type as 'blob'
	});

	const contentDisposition = response.headers['content-disposition'];
	const name = contentDisposition?.split('="')[1].replace('"', '');

	return {
		data: {
			data: response.data,
			name,
			type: response.headers['content-type'],
		},
		status: response?.status,
	};
}
export async function getQFile(g: any, from: any, to: any) {
	const token = localStorage.getItem('accessToken');
	const response = await axios.get(`${baseUrl}/views/doc/quartile?from=${from}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob', // specify the response type as 'blob'
	});

	const contentDisposition = response.headers['content-disposition'];
	const name = contentDisposition?.split('="')[1].replace('"', '');

	return {
		data: {
			data: response.data,
			name,
			type: response.headers['content-type'],
		},
		status: response?.status,
	};
}
export async function getClusterFile(g: any, from: any, to: any) {
	const token = localStorage.getItem('accessToken');
	const response = await axios.get(`${baseUrl}/views/doc/cluster?group=${g}&from=${from}&to=${to}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob', // specify the response type as 'blob'
	});

	const contentDisposition = response.headers['content-disposition'];
	const name = contentDisposition?.split('="')[1].replace('"', '');

	return {
		data: {
			data: response.data,
			name,
			type: response.headers['content-type'],
		},
		status: response?.status,
	};
}
export async function getNPIFile(g: any, from: any, to: any) {
	const token = localStorage.getItem('accessToken');
	const response = await axios.get(`${baseUrl}/views/doc/npi?group=${g}&from=${from}&to=${to}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob', // specify the response type as 'blob'
	});

	const contentDisposition = response.headers['content-disposition'];
	const name = contentDisposition?.split('="')[1].replace('"', '');

	return {
		data: {
			data: response.data,
			name,
			type: response.headers['content-type'],
		},
		status: response?.status,
	};
}
export async function deleteTUFile(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/administration/tufile/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteNatMeasure(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/register/natmeasure?id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getOJNumbers(offset: any, limit: any, year: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/administration/oj/numbers?offset=${offset}&limit=${limit}&year=${year}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getViewsNPI() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/views/npi-list`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getViewsCluster() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/views/cluster-list`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getUsersByInstitution(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/administration/users/by/institution?id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getScreeningTree(term: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/jurisdiction/screenings?term=${term}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getJurisdictions(offset: any, limit: any, institution: any, group: any, filter: any, celex: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/jurisdiction/get?limit=${limit}&offset=${offset}&institution=${institution}&group=${group}&filter=${filter}&celex=${celex}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getTUList(offset: any, limit: any, celex: any, natMeasure: any, file: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/administration/tu/list?offset=${offset}&limit=${limit}&celex=${celex}&file=${file}&natMeasure=${natMeasure}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getInstitutions(offset: any, limit: any, term: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/administration/institutions?offset=${offset}&limit=${limit}&term=${term}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getUserById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',

		url: `${baseUrl}/administration/userby/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function loadOJDateById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',

		url: `${baseUrl}/administration/oj/date/by/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getOJbyId(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',

		url: `${baseUrl}/administration/oj/by/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function loadInstitutionById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/administration/institution/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getHelpPageByName(name: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/help/page/${name}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getSettingsTypeof(type: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/settings/typeof/${type}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getSettingByName(name: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/settings/name/${name}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAllRolesWithFeatures() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/settings/roles/feature`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getHelpList() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/help/list`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getNoCelexRelations() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/register/natmeasure/norelationcelex`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getHarmonizations(offset: any, limit: any, id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/register/natmeasure/harmonizations?id=${id}&limit=${limit}&offset=${offset}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getNationalMeasureInChargeInstitutions(offset: any, limit: any, id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/register/natmeasure/inchargeinstitutions?id=${id}&limit=${limit}&offset=${offset}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAllFeatures() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/settings/features/all`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteRole(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/settings/user/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteOJNumber(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/administration/oj/number/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteNoCelex(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/administration/nocelexregister/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteInstitution(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/administration/institutions/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteOJ(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/administration/oj/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteNationalMeasureRelation(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/register/natmeasure/relation?id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteNationalMeasureInChargeInstitutions(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/register/natmeasure/inchargeinstitution?id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteHarmonization(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/register/natmeasure/harmonizations?id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function insertMeasureInChargeInstitutions(h: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/register/measure/inchargeinstitution`,
		data: h,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMeasureInChargeInstitutions(offset: any, limit: any, id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/register/measure/inchargeinstitutions?id=${id}&limit=${limit}&offset=${offset}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteMeasureInChargeInstitutions(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/register/measure/inchargeinstitution?id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getNationalMeasureRelationTypes() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/register/natmeasure/relation/types`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getNationalMeasureRelations(offset: any, limit: any, id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/register/natmeasure/relations?offset=${offset}&limit=${limit}&id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getExpenditureTypes() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/register/expenditure/types`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteNatMeasureStructure(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/register/natmeasure/group/structure?id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteUser(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/administration/user/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getAllProducts(type: string, subCategory: string | null = null) {
	const token = localStorage.getItem('accessToken');
	let url = `${baseUrl}/product/all?c=${type.replaceAll('&', '%26')}${!!subCategory ? `&sc=${subCategory.replaceAll('&', '%26')}` : ''}`;
	const options: AxiosRequestConfig = {
		method: 'GET',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getFeatured() {
	const token = localStorage.getItem('accessToken');
	let url = `${baseUrl}/product/featured`;
	const options: AxiosRequestConfig = {
		method: 'GET',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getCategoryDescription(type: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/category/desc?c=${type.replaceAll('&', '%26')}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getProductInfo(o1: string, o2: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/by?o1=${o1}&o2=${o2}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAllSymbols() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/symbols/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getSymbolsForProduct(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/allowed-symbols?productId=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getFontsForProduct(id: string = '') {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/allowed-fonts?productId=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getDrafts() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/drafts`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getDraftsById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/draft/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteDraftsById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/product/draft/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getEFileById(id: any) {
	const token = await localStorage.getItem('accessToken');
	const response = await axios.get(`${baseUrl}/register/efile/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob', // specify the response type as 'blob'
	});

	const contentDisposition = response.headers['content-disposition'];
	const name = contentDisposition?.split('="')[1].replace('"', '');
	return {
		data: { data: response.data, name, type: response.headers['content-type'] },
		status: response?.status,
	};
}
export async function createOrder(data: any) {
	console.log(data);

	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/order/create`,
		data: data,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function createCustomerOrder(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/customer/order/create`,
		data: data,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getProductByOptions(optionIds: any[] = []) {
	const token = await localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/product/by?${optionIds.map((option, index) => `o${index + 1}=${option}`).join('&')}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getProductById(id: string) {
	const token = await localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/product/by?productId=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function createDraft(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/product/draft`,
		data: data,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export const getImage = (imageId: string) => `${baseUrl}/product/file/${imageId}`;

export async function createCountries(list: any[]) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/location/countries`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: list,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getCountries() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/countries`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAllCategories() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/category/allCategories`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getCustomerByUserId() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/customers/getCustomerByUserId`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function editCustomer(id: string, data: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/customers/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function deleteCustomer(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/customers/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function changePassword(payload: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/admin/change-password`,
		data: payload,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function uploadFile(data: any | { file: File; selectable: string }) {
	const token = localStorage.getItem('accessToken');
	const formData = new FormData();
	formData.append('file', data.file);
	formData.append('selectable', data.selectable);
	console.log('form data ', formData);
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/file/upload`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function updateUser(payload: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/admin/update-user`,
		data: payload,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getMyOrders() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/user/order/my`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getOrderByOrderId(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/user/order/by/${id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getOrderItemsByOrderId(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/user/order/items/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMyAddresses() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/user/profile/address`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
// export async function getAddressById(id: any) {
// 	const token = localStorage.getItem('accessToken');
// 	const options: AxiosRequestConfig = {
// 		method: 'GET',
// 		url: `${baseUrl}/address/${id}`,
// 		headers: {
// 			Authorization: `Bearer ${token}`,
// 		},
// 	};
// 	return axios(options)
// 		.then((response) => handleSuccess(response))
// 		.catch((error) => handelError(error));
// }
export async function getMyAddress() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/user/profile/address`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAddressById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/user/profile/address/by/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getRegions() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/regions`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMunicipalities() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/municipalities`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getCities() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/cities`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function editAddress(id: any, addressInfo: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/address/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: addressInfo,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function createAddress(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/profile/address/insert`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAvailableShippingMethods(ids: any[]) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/product/available-shippings`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: { products: ids },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAvailablePayments(ids: any[]) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/product/available-payments`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: { products: ids },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function changeDefaultShippingAddress(customerId: any, addressId: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/customers/address/change_default_shipping_address?customer_id=${customerId}&address_id=${addressId}`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function changeDefaultBillingAddress(customerId: any, addressId: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/customers/address/change_default_billing_address?customer_id=${customerId}&address_id=${addressId}`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function setDefaultAddress(b: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/profile/address/change-default`,
		data: b,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	return axios(options).then((response) => handleSuccess(response));
}
export async function getAllFonts() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/fonts/all`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getAllowedDesigns(id: string) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/product/allowed-designs?productId=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getSingleStaticPages() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/ssp/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getProductDesign(id: string) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/product/product-design/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getUserShippingAddress() {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/user/profile/address/shipping/default`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMenuCategories() {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/product/categories/menu`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function deleteAddress(id: string) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'DELETE',
		url: `${baseUrl}/user/profile/address/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}