import React from 'react';
import { Link } from 'react-router-dom';

function ProductGroup({ title = 'Choose your color:', selectedOption = {}, options = ['white', 'purple', 'pink', 'blue', 'orange'], isColor = false, product = {} }: { options?: any[]; isColor?: boolean; title?: string; selectedOption: any; product: any }) {
	return (
		<div>
			<div>
				<p className="text-[16px] font-medium">
					{title}
					{title.includes(':') ? '' : ':'}
				</p>
			</div>
			<div className="flex flex-row gap-[30px] mt-[14px]">
				{options.map((option, index) => (
					<OptionButton option={option} selected={option.id === selectedOption} product={product} isColor={isColor} />
				))}
			</div>
		</div>
	);
}

const OptionButton = ({ option, selected = false, isColor = false, product = {} }: { option: any; selected: boolean; isColor: boolean; product: any }) => {
	return (
		<Link to={`/product/${product.urlSlug}--${option?.id}`}>
			<div className="flex flex-col items-center cursor-pointer ">
				<div className={`min-w-[40px] h-[40px] flex items-center justify-center rounded-full px-[5px] ${selected ? 'border border-[#F89DBD]' : ''}`}>
					{!isColor && <div className="font-normal text-[14px] capitalize">{option.name}</div>}
					{isColor && <div style={{ backgroundColor: !!option?.color ? option?.color : '#C52A2A' }} className="border border-[#F1F1FE] rounded-full h-[30px] w-[30px]"></div>}
				</div>
				{isColor && <div className="mt-[8px] text-[14px] font-normal capitalize">{option.name}</div>}
			</div>
		</Link>
	);
};

export default ProductGroup;
