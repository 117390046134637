import React, { useMemo, useRef, useState } from 'react';
import ShippingInfo from './ShippingInfo';
import UploadImageButton from '../configurator/Design/UploadImageButton';
import { ReactComponent as PurpleTimes } from '../../assets/icons/PurpleTimes.svg';
import { getImage, uploadFile } from '../../server/server';
import Loading from '../../common/Loading';
import { dataUrlToFile } from '../../util/util';
import { useCart } from '../../hooks/useCart';
import { fabric } from 'fabric';
import { DEFAULT_COLOR } from '../../Constants';
interface Type {
	product: any;
	openCartModal: () => void;
}

function dataURLToBlob(dataURL: string) {
	const byteString = atob(dataURL.split(',')[1]);
	const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type: mimeString });
}

async function getImageFromVideo(url: string) {
	const video = document.createElement('video');
	const canvas = document.createElement('canvas');
	const context: any = canvas.getContext('2d');

	video.crossOrigin = 'anonymous';

	return new Promise((resolve, reject) => {
		video.src = url;
		video.load();

		video.addEventListener('loadeddata', () => {
			video.currentTime = 0;
			video.addEventListener(
				'seeked',
				() => {
					context.drawImage(video, 0, 0, canvas.width, canvas.height);
					const dataUrl = canvas.toDataURL('image/png');
					const blob = dataURLToBlob(dataUrl);
					const file = new File([blob], 'first-frame.png', { type: 'image/png' });
					resolve(file);
				},
				{ once: true }
			);
		});

		video.addEventListener('error', (e) => {
			reject(new Error('Video loading failed'));
		});
	});
}

const createFabricTextObject = (obj: any, index: number) => {
	return new fabric.Textbox(obj.value, {
		top: 10 + index * 30,
		left: 30,
		editable: true,
		hasBorders: true,
		padding: 0,
		fill: DEFAULT_COLOR,
		splitByGrapheme: true,
	}).toObject();
};
function DigitalProduct({ product, openCartModal }: Type) {
	const [productFields, setProductFields] = useState(product.digitalProductFields);
	const [orderProgress, setOrderProgress] = useState(false);
	const { addToCart } = useCart();

	const buttonDisabled = useMemo(() => {
		return !productFields.every((e: any) => !!e.imageId || !!e?.value?.trim());
	}, [productFields]);

	const changeText = (e: any, index: number) => {
		let fields = [...productFields];
		fields[index].value = e?.target?.value;
		setProductFields(fields);
	};

	const updateImage = (image: any, index: number) => {
		let fields = [...productFields];
		fields[index] = image;
		setProductFields(fields);
	};

	const addToCartSubmit = async () => {
		setOrderProgress(true);
		const normalizedObjects: any = productFields?.filter((e: any) => !e.imageId).map(createFabricTextObject);
		const imageUrl = getImage(product.files[0].file.id);
		const file = await getImageFromVideo(imageUrl);
		const resp = await uploadFile({ file: file, selectable: 'false' });
		if (resp.status === 201) {
			const data = {
				product,
				design: normalizedObjects,
				usedImageIds: productFields?.filter((e: any) => !!e.imageId).map((e: any) => e.imageId),
				imagePreviewId: resp.data.id,
			};
			console.log(data);

			addToCart(data);
			openCartModal();
		}
	};

	return (
		<div>
			<div className="flex flex-col gap-[30px] pb-[30px]">
				{productFields?.map((e: any, index: number) => {
					const type = e.type;
					return (
						<div className="flex flex-col gap-[14px]">
							<label htmlFor="" className="text-[16px] font-normal">
								{e.name}:
							</label>
							{type === 'text' && <input placeholder="Enter text here..." className="h-[44px] border-b border-b-black w-full outline-none" value={e.value} onChange={(e) => changeText(e, index)} />}
							{type !== 'text' && <DigitalImage image={e} updateImage={(image: any) => updateImage(image, index)} />}
						</div>
					);
				})}
			</div>
			<div className="h-[1px] bg-[#D4C4F6]" />
			<div className="my-[30px]">
				<ShippingInfo data={product?.zone?.shippingOptions ?? []} />
			</div>
			<div>
				<button disabled={buttonDisabled} className={`uppercase flex flex-row h-[44px] items-center justify-center ${buttonDisabled ? 'bg-[#DFDFDF]' : 'bg-[#21187F]'} rounded-full w-full text-center text-white font-bold`} onClick={addToCartSubmit}>
					{orderProgress ? <Loading height={30} /> : 'Add to cart'}
				</button>
			</div>
			<canvas id="canvas" width="640" height="360" style={{ display: 'none' }}></canvas>
		</div>
	);
}

const DigitalImage = ({ image, updateImage }: { image: any; updateImage: (image: any) => void }) => {
	const fileInputRef = useRef<any>(null);
	const showImage = image.imageId;
	const [loader, setLoader] = useState(false);

	const onImageUpload = async (event: any) => {
		if (!event.target.files || event.target.files.length === 0) {
			return;
		}
		setLoader(true);
		const file = event.target.files[0];
		const resp = await uploadFile({ file: file, selectable: 'false' });
		if (resp.status === 201) {
			const imageData = resp.data;
			updateImage({ ...image, imageId: imageData.id });
			setLoader(false);
		}
	};

	const removeImage = () => {
		delete image['imageId'];
		updateImage({ ...image });
	};

	return (
		<>
			{!!showImage || loader ? (
				<div className="h-[140px] w-[140px] bg-[#F1F1FEB3] rounded-[10px] items-center justify-center flex relative">
					{loader && <Loading />}

					{!!showImage && (
						<>
							<button className="top-[8px] right-[9px] w-[24px] h-[25px] rounded-full bg-[#F1F1FE] flex items-center justify-center absolute" onClick={removeImage}>
								<PurpleTimes />
							</button>
							<img src={getImage(showImage)} width={140} height={140} alt="" />
						</>
					)}
				</div>
			) : (
				<div>
					<UploadImageButton onClick={() => fileInputRef?.current?.click()} hoverElement={() => {}} leaveHover={() => {}} />
				</div>
			)}
			<input type="file" accept="image/png, image/jpeg, image/jpg" ref={fileInputRef} onChange={onImageUpload} className="hidden" />
		</>
	);
};

export default DigitalProduct;
