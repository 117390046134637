import React, { useEffect, useState } from 'react';
import Loading from './Loading';
import { ReactComponent as Logo } from '../assets/logo.svg';

function LoadingScreen({ visible }: { visible: boolean }) {
	const [hide, setHide] = useState(false);

	useEffect(() => {
		if (!visible && !hide) {
			setTimeout(() => setHide(true), 230);
		}
	}, [visible, hide]);

	return (
		<div
			className={`absolute h-full w-full z-10 bg-[#ffffff] flex items-center justify-center flex-col gap-[60px] pb-[260px] transition-all ease-in-out duration-[230ms] ${visible ? 'opacity-100' : 'opacity-0'} ${hide ? 'hidden' : ''}`}
			// style={{ transitionProperty: 'visibility', transitionDelay: '230ms' }}
		>
			<Logo width={300} height={200} />
			<Loading />
		</div>
	);
}

export default LoadingScreen;
