import React, { createContext, useContext, useEffect, useState } from 'react';
import { whoAmI } from '../server/server';

const UserContext = createContext<any>(undefined);

const UserProvider = (props: any) => {
	//   const user = {} || JSON.parse(localStorage.getItem('user'))
	//   const setUser = useAuth().data.setUser

	const _user = JSON.parse(localStorage.getItem('user') || '{}');
	const [user, setUser] = useState(_user);
	const [loadingUser, setLoadingUser] = useState(true);
	const isSignedIn = !!user.email;

	useEffect(() => {
		checkUser();
	}, []);

	const checkUser = async () => {
		const resp = await whoAmI();
		if (resp.status === 200) {
			localStorage.setItem('user', JSON.stringify({ ...resp.data }));
			setUser(resp.data);
		} else {
			localStorage.clear();
			setUser({});
		}
		setLoadingUser(false);
	};

	return <UserContext.Provider value={{ user, setUser, isSignedIn, loadingUser }} {...props} />;
};

function useUser() {
	const context = useContext(UserContext);

	if (context === undefined) {
		throw new Error(`useUser must be used within a UserProvider`);
	}

	return context;
}

export { UserProvider, useUser };
