import { useEffect, useState } from 'react';
import './App.css';
import { Toaster } from 'react-hot-toast';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { getAllFonts, getImage, getMenuCategories } from './server/server';
import { createGlobalStyle } from 'styled-components';
import { useMenuItems } from './hooks/useMenuItems';
import LoadingScreen from './common/LoadingScreen';
import { HelmetProvider } from 'react-helmet-async';
import AppRouter from './AppRouter';
import { GlobalDebug } from './util/remove-console';

function App() {
	const [fonts, setFonts] = useState([]);
	const { menuItems, setMenuItems } = useMenuItems();
	const [initLoading, setInitLoading] = useState(true);

	useEffect(() => {
		loadInit();
	}, []);

	const loadInit = async () => {
		await Promise.all([loadMenuItems(), loadFonts()]);
		setInitLoading(false);
	};

	const loadMenuItems = async () => {
		const resp = await getMenuCategories();
		if (resp.status == 200) {
			setMenuItems(resp.data);
		}
	};

	const loadFonts = async () => {
		const resp = await getAllFonts();
		if (resp.status === 200) {
			setFonts(resp.data);
		} else {
			console.log('fonts are not loaded.');
		}
	};

	const GlobalStyle = createGlobalStyle`
		body { ${fonts.map(
			(font: any) =>
				`@font-face {
						font-family: ${font.name};
						src: url(${getImage(font.imageId)}) format('truetype');
						font-style: normal;
						font-weight: 500;
					}`
		)} }`;
	const helmetContext = {};

	useEffect(() => {
		(process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') && GlobalDebug(false, true);
	}, []);
	return (
		<div>
			<HelmetProvider context={helmetContext}>
				<LoadingScreen visible={initLoading} />
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<GlobalStyle />
					<AppRouter />
					<Toaster
						position="top-right"
						reverseOrder={false}
						containerStyle={{
							top: 125,
						}}
					/>
				</LocalizationProvider>
			</HelmetProvider>
		</div>
	);
}

export default App;
