import React, { useEffect, useState } from 'react';
import { ReactComponent as MasterCardCircle } from '../../assets/icons/MasterCardCircle.svg';
import { ReactComponent as Dropdown } from '../../assets/icons/Dropdown.svg';
import { getUserShippingAddress } from '../../server/server';
import { useUser } from '../../auth/UseUser';
import { Address, ShippingAddress } from '../user-profile/sections/orders/OrderInterfaces';
import { useNavigate } from 'react-router-dom';

function CheckoutSignedIn({
	availablePayments,
	paymentMethod,
	setPaymentMethod,
	availableShippingMethods,
	shippingMethod,
	setShippingMethod,
	setShippingAddress,
	shippingAddress,
}: {
	availablePayments: any[];
	paymentMethod: any;
	setPaymentMethod: (value: any) => void;
	availableShippingMethods: any[];
	setShippingMethod: (value: any) => void;
	shippingMethod: any;
	setShippingAddress: (value: any) => void;
	shippingAddress: any;
}) {
	let navigate = useNavigate();

	useEffect(() => {
		scrollToShippingAddress();
		getShippingAddress();
	}, []);

	const scrollToShippingAddress = () => {
		document?.getElementById('scroll-div')?.scroll(200, 200);
	};

	const getShippingAddress = async () => {
		const resp = await getUserShippingAddress();
		if (resp.status === 200) {
			setShippingAddress(resp.data);
		}
	};

	return (
		<div className="col-span-3">
			<div className="w-full flex flex-col bg-white rounded-xl p-10">
				<p className="text-[20px] leading-[28px] font-medium text-black">Shipping Address</p>
				{shippingAddress?.id ? (
					<div className="mt-5">
						<div className="w-full flex flex-row">
							<p className="text-[16px] leading-[24px] font-semibold text-black w-[167px]">Full Name:</p>
							<p className="text-[16px] leading-[24px] font-normal text-black ">
								{shippingAddress?.firstName} {shippingAddress?.lastName}
							</p>
						</div>
						<div className="w-full flex flex-row mt-[10px]">
							<p className="text-[16px] leading-[24px] font-semibold text-black w-[167px]">Address:</p>
							<p className="text-[16px] leading-[24px] font-normal text-black ">{shippingAddress?.street}</p>
						</div>
						<div className="w-full flex flex-row mt-[10px]">
							<p className="text-[16px] leading-[24px] font-semibold text-black w-[167px]">City:</p>
							<p className="text-[16px] leading-[24px] font-normal text-black ">{shippingAddress?.city}</p>
						</div>
						<div className="w-full flex flex-row mt-[10px]">
							<p className="text-[16px] leading-[24px] font-semibold text-black w-[167px]">State:</p>
							<p className="text-[16px] leading-[24px] font-normal text-black ">{shippingAddress?.country?.name}</p>
						</div>
						<div className="w-full flex flex-row mt-[10px]">
							<p className="text-[16px] leading-[24px] font-semibold text-black w-[167px]">Phone number:</p>
							<p className="text-[16px] leading-[24px] font-normal text-black ">{shippingAddress?.phone}</p>
						</div>
						<div className="w-full flex flex-row mt-[10px]">
							<p className="text-[16px] leading-[24px] font-semibold text-black w-[167px]">Zip code:</p>
							<p className="text-[16px] leading-[24px] font-normal text-black ">{shippingAddress?.zip}</p>
						</div>
					</div>
				) : (
					<p className="mt-[20px]">No default address found</p>
				)}
				<div className="w-full flex flex-row mt-[20px]">
					<p className="text-[16px] leading-[24px] font-semibold text-laykaDarkPurple hover:underline cursor-pointer" onClick={() => navigate(`/profile/address${shippingAddress?.id ? '?id=' + shippingAddress?.id : ''}`)}>
						{shippingAddress?.id ? 'Edit' : 'Add'}
					</p>
				</div>
			</div>

			<div className="w-full flex flex-col bg-white rounded-xl p-10 mt-5">
				<p className="text-[20px] leading-[28px] font-medium text-black">Shipping Method</p>
				<div className="mt-[30px]">
					{availableShippingMethods.map((method) => (
						<button className="flex flex-row items-center justify-between w-full py-[10px]" onClick={() => setShippingMethod(method)}>
							<p className="text-[16px] leading-[20px] text-black  font-normal">{method.name}</p>
							<div className="flex items-center justify-center border border-laykaDarkPurple min-h-[16px] min-w-[16px] rounded-full cursor-pointer">
								<div className={`min-w-[8px] min-h-[8px] bg-laykaPink ${shippingMethod.id === method.id ? 'block' : 'hidden'} rounded-full`}></div>
							</div>
						</button>
					))}
				</div>
			</div>
			<div className="w-full flex flex-col bg-white rounded-xl p-10 mt-5 transition">
				<p className="text-[20px] leading-[28px] font-medium text-black">Payment Method</p>
				<div className="mt-[30px]">
					{availablePayments.map((method) => (
						<button className="flex flex-row items-center w-full justify-between  py-[10px]" onClick={() => setPaymentMethod(method)}>
							<p className="text-[16px] leading-[20px] text-black  font-normal">{method.name}</p>
							<div className="flex items-center justify-center border border-laykaDarkPurple min-h-[16px] min-w-[16px] rounded-full cursor-pointer">
								<div className={`min-w-[8px] min-h-[8px] bg-laykaPink ${paymentMethod?.id === method.id ? 'block' : 'hidden'} rounded-full`}></div>
							</div>
						</button>
					))}
				</div>
			</div>
		</div>
	);
}

export default CheckoutSignedIn;
